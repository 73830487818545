import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/56c857c7/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Tabs>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Tab>`}</inlineCode>{` components are used together to display and swap between content. Like all of the MDX components, you’ll need to give the content you pass in a line of whitespace. When in doubt, follow the examples. Everything should be left aligned, indenting `}<inlineCode parentName="p">{`<Tab>`}</inlineCode>{` will result in errors.`}</p>
    </PageDescription>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.402010050251256%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQY0x3MT0hTcQDA8d/ewxgG4mQqQi6k50LRjSJs0KGkltUhkrYio8XS1ALpUIcozYZJfyCIQLA6SGkho5WZEMZKGAkeNFc6PUQEZVpUU/faDrX3vv3o8OF7+wqlci1KZS5KhQ3FWY2ysUp2C6q2G7V8J6qjHtV+ElHYRGnBUTbn+3HZjrAj102d1YHXWoZ3TbFUKBUhVN96VP86VF8F4sBhyYfY34TY043Y24mltpecmpcIzyieqgiNG/ppcIa5WHSMq7btdBfs4kqeg668YqkEYQ9twx7ykH+5Dkt7O5aO81gu3EKcjSHORVHaZrE2phHNOv76JD2137nmXeWF6zoTWpBxZytvta3EtWqmNTfCNdTApqFDlD89hYg8RjwZJCc8Rkm/jn1gGXdfBv8d2HcPbt6AVx0wHIKF0yNkgj3oJ+6SPX4GM9CMEWhFBOa6CM53cnDuNmWzM5Qm4tS8/0TbJLRMwaUJk76YQe8bk+ioQeKZwfRzSD2agfsxjAfjmAMRzIeDUhjxOj3FWHqS6O8EIymdYV0nmsrwbtkkviIHSYOPPww+/DRY+pZl5etfkotZ/iysYn75hSFlPy9Ji//9A4LILAKbqMyBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/0eda2/colors.webp 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/460e2/colors.webp 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/e0ca3/colors.webp 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/b7e7d/colors.webp 1592w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/1a057/colors.png 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/8418d/colors.png 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c7b8d/colors.png 1592w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png",
              "alt": "color array",
              "title": "color array",
              "loading": "lazy"
            }}></img>{`
      `}</picture>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "30.402010050251256%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQY0x3MT0hTcQDA8d/ewxgG4mQqQi6k50LRjSJs0KGkltUhkrYio8XS1ALpUIcozYZJfyCIQLA6SGkho5WZEMZKGAkeNFc6PUQEZVpUU/faDrX3vv3o8OF7+wqlci1KZS5KhQ3FWY2ysUp2C6q2G7V8J6qjHtV+ElHYRGnBUTbn+3HZjrAj102d1YHXWoZ3TbFUKBUhVN96VP86VF8F4sBhyYfY34TY043Y24mltpecmpcIzyieqgiNG/ppcIa5WHSMq7btdBfs4kqeg668YqkEYQ9twx7ykH+5Dkt7O5aO81gu3EKcjSHORVHaZrE2phHNOv76JD2137nmXeWF6zoTWpBxZytvta3EtWqmNTfCNdTApqFDlD89hYg8RjwZJCc8Rkm/jn1gGXdfBv8d2HcPbt6AVx0wHIKF0yNkgj3oJ+6SPX4GM9CMEWhFBOa6CM53cnDuNmWzM5Qm4tS8/0TbJLRMwaUJk76YQe8bk+ioQeKZwfRzSD2agfsxjAfjmAMRzIeDUhjxOj3FWHqS6O8EIymdYV0nmsrwbtkkviIHSYOPPww+/DRY+pZl5etfkotZ/iysYn75hSFlPy9Ji//9A4LILAKbqMyBAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/0eda2/colors.webp 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/460e2/colors.webp 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/e0ca3/colors.webp 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/b7e7d/colors.webp 1592w"],
                  "sizes": "(max-width: 1152px) 100vw, 1152px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/1a057/colors.png 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/8418d/colors.png 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c7b8d/colors.png 1592w"],
                  "sizes": "(max-width: 1152px) 100vw, 1152px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png",
                  "alt": "color array",
                  "title": "color array",
                  "loading": "lazy"
                }}></img>{`
      `}</picture>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "30.402010050251256%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQY0x3MT0hTcQDA8d/ewxgG4mQqQi6k50LRjSJs0KGkltUhkrYio8XS1ALpUIcozYZJfyCIQLA6SGkho5WZEMZKGAkeNFc6PUQEZVpUU/faDrX3vv3o8OF7+wqlci1KZS5KhQ3FWY2ysUp2C6q2G7V8J6qjHtV+ElHYRGnBUTbn+3HZjrAj102d1YHXWoZ3TbFUKBUhVN96VP86VF8F4sBhyYfY34TY043Y24mltpecmpcIzyieqgiNG/ppcIa5WHSMq7btdBfs4kqeg668YqkEYQ9twx7ykH+5Dkt7O5aO81gu3EKcjSHORVHaZrE2phHNOv76JD2137nmXeWF6zoTWpBxZytvta3EtWqmNTfCNdTApqFDlD89hYg8RjwZJCc8Rkm/jn1gGXdfBv8d2HcPbt6AVx0wHIKF0yNkgj3oJ+6SPX4GM9CMEWhFBOa6CM53cnDuNmWzM5Qm4tS8/0TbJLRMwaUJk76YQe8bk+ioQeKZwfRzSD2agfsxjAfjmAMRzIeDUhjxOj3FWHqS6O8EIymdYV0nmsrwbtkkviIHSYOPPww+/DRY+pZl5etfkotZ/iysYn75hSFlPy9Ji//9A4LILAKbqMyBAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/0eda2/colors.webp 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/460e2/colors.webp 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/e0ca3/colors.webp 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/b7e7d/colors.webp 1592w"],
                  "sizes": "(max-width: 1152px) 100vw, 1152px",
                  "type": "image/webp"
                }}></source>{`
        `}<source parentName="picture" {...{
                  "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/1a057/colors.png 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/8418d/colors.png 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/c7b8d/colors.png 1592w"],
                  "sizes": "(max-width: 1152px) 100vw, 1152px",
                  "type": "image/png"
                }}></source>{`
        `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/c0fc9864a6ce4ef42cef74f3f451329e/c801a/colors.png",
                  "alt": "color array",
                  "title": "color array",
                  "loading": "lazy"
                }}></img>{`
      `}</picture>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`// Start with the wrapper component (Tabs)
<Tabs>

<Tab label="Text">

The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you'll need to use our \`Row\` and \`Column\` helpers as demonstrated in the "Two images" tab.

</Tab>
`}</code></pre>
    <h4 {...{
      "id": "single-image"
    }}>{`Single image`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Single image">
  If your content is full width, you don't need \`Row\` and \`Column\` ![color
  array](images/colors.png)
</Tab>
`}</code></pre>
    <h4 {...{
      "id": "multiple-images"
    }}>{`Multiple images`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Two images">

<Row>
<Column colSm={6} colLg={6}>

Column one

![color array](images/colors.png)

</Column>
<Column colSm={6} colLg={6}>

Column two

![color array](images/colors.png)

</Column>
</Row>

</Tab>

// Make sure you close the wrapper component
</Tabs>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      